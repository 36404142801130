import { OfferType } from "entities/checkout";
import { Course } from "entitys/course";
import CourseServices from 'services/courses';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import OfferService from 'services/checkout/offers';

type OfferContextProps = {
  findOffer: (offerId: string) => Promise<void>
  offer: OfferType
  course: Course
  cleanOffer: () => void
}

const OfferContext = createContext<OfferContextProps>({} as OfferContextProps);

export const OfferContextProvider = ({ children }: { children: ReactNode }) => {
  const [offer, setOffer] = useState<OfferType>(null!);
  const [course, setCourse] = useState<Course>(null!);

  const findOffer = useCallback(async (offerId: string) => {
    try {
      const offerData = await OfferService.show(offerId);
      const { data } = await CourseServices.getCourse(offerData.item.reference);

      setOffer(offerData);
      setCourse(data);
    } catch (error) {
      console.log('Erro ao carregar oferta');
    }
  }, []);

  const cleanOffer = useCallback(() => setOffer(null!), []);

  const config = useMemo(() => ({ findOffer, offer, course, cleanOffer }), [offer, course, cleanOffer, findOffer])

  return (
    <OfferContext.Provider value={config}>
      {children}
    </OfferContext.Provider>
  )
};

export const useOfferContext = () => useContext(OfferContext);
