import {
  GoogleLoginRequest,
  LoginRequest,
  SignUpRequest,
  FacebookLoginRequest,
} from 'entities/user';
import api from '../index';

async function login(payload: LoginRequest) {
  const { email, password } = payload;
  return api.post('/auth/users/login', { email, password });
}

async function register(payload: SignUpRequest) {
  const { name, email, password } = payload;
  const origin_url = window.location.href;

  const data = {
    name,
    email,
    password,
    origin_url,
    terms: {
      privacy: true,
      usage: true,
      privacy_yduqs: true,
    },
  }

  const response = await api.post('/auth/users/register', data);
  return response.data;
}

async function loginGoogle(payload: GoogleLoginRequest) {
  const { token, code } = payload;
  const origin_url = window.location.href;
  return api.post('/auth/users/google', { token, code, origin_url });
}

async function loginFacebook(payload: FacebookLoginRequest) {
  const { token } = payload;
  const origin_url = window.location.href;
  return api.post('/auth/users/facebook', { token, origin_url });
}

async function refreshToken(payload: string) {
  return api.post('/auth/sessions/refresh', { token: payload });
}

async function askRecoverPasswordCode(email: string) {
  return api.post('/auth/users/reset_password/ask', { email });
}

async function checkPasswordRecoverCode(email: string, code: string) {
  return api.post('/auth/users/reset_password/check', { email, code });
}

async function resetPassword(email: string, code: string, password: string) {
  return api.post('/auth/users/reset_password/confirm', { email, code, password });
}

async function getInfo() {
  return api.get('/auth/users/me');
}

async function sso() {
  return api.post('/auth/users/sso');
}

export default {
  login,
  register,
  refreshToken,
  loginGoogle,
  loginFacebook,
  getInfo,
  sso,
  askRecoverPasswordCode,
  checkPasswordRecoverCode,
  resetPassword,
};
