import api from '../index';

async function listCourses(filter: any) {
  return api.get('/learn/courses', { params: filter });
}

async function getCourse(courseId: string) {
  return api.get(`/learn/courses/${courseId}`);
}

async function getEnrolledCourses() {
  return api.get('/learn/courses/enrollments');
}

async function getSuggestedCourses() {
  return api.get('/learn/courses/suggestions');
}

async function getEnrolledCourse(courseId: string) {
  return api.get(`/learn/courses/enrollments/${courseId}`);
}

async function getContent(contentId: string) {
  return api.get(`/learn/contents/${contentId}`);
}

async function createView(contentId: string) {
  return api.post(`/learn/contents/${contentId}/view`);
}

async function destroyView(contentId: string) {
  return api.delete(`/learn/contents/${contentId}/view`);
}

interface CreateRatingInterface {
  kind: string;
  reference: string;
  score: number;
  motive: string | undefined | null;
}

async function createRating({ kind, reference, score, motive }: CreateRatingInterface) {
  // scores: like => 1; dislike => -1; none => 0;
  return api.post('/learn/rating', { kind, reference, score, motive });
}

async function getCertificate(enrollmentId: string) {
  return api.get(`/learn/certificates/${enrollmentId}`);
}

export default {
  listCourses,
  getEnrolledCourses,
  getSuggestedCourses,
  getEnrolledCourse,
  getContent,
  getCourse,
  createView,
  destroyView,
  createRating,
  getCertificate,
};
