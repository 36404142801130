import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthApi from 'services/auth';
import api from 'services';
import {
  AuthLoginResponse,
  LoginRequest,
  UserType,
  GoogleLoginRequest,
  FacebookLoginRequest,
  UserInfo,
} from 'entities/user';
import { ApiErrorType } from 'entities/error';
import { showLoading, hideLoading } from 'stores/modals';
import { setSession, clearSession } from "services/sessions";

const initialState: UserType = {
  authenticated: false,
  name: 'Aluno',
  email: null,
  id: null,
  redirectLocation: '/',
};

export const signInUser = createAsyncThunk<
  AuthLoginResponse,
  LoginRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'user/signin',
  async (payload: LoginRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      AuthApi.login(payload)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const signInUserGoogle = createAsyncThunk<
  AuthLoginResponse,
  GoogleLoginRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'user/signinGoogle',
  async (payload: GoogleLoginRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      AuthApi.loginGoogle(payload)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const signInUserFacebook = createAsyncThunk<
  AuthLoginResponse,
  FacebookLoginRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'user/signinFacebook',
  async (payload: FacebookLoginRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      AuthApi.loginFacebook(payload)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const callRefreshToken = createAsyncThunk<
  AuthLoginResponse,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'user/callRefreshToken',
  async (payload, thunkAPI) => (
    AuthApi.refreshToken(payload)
      .then((response) => (response.data))
      .catch((error) => (thunkAPI.rejectWithValue(error.response.data)))
  ),
);

export const setUserInfo = createAsyncThunk<
  UserInfo,
  void,
  {
    rejectValue: ApiErrorType,
  }
>(
  'user/setUserInfo',
  async (_, thunkAPI) => (
    AuthApi.getInfo()
      .then((response) => (response.data))
      .catch((error) => (thunkAPI.rejectWithValue(error.response.data)))
  ),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthLoginResponse>) => {
      const { refresh_token, token } = action.payload;
      state.authenticated = true;
      setSession({
        token,
        refreshToken: refresh_token,
      });
      state.name = '';
    },
    clearAuth: (state) => {
      api.defaults.headers.common.Authorization = '';
      state.authenticated = false;
      clearSession();
      state.name = 'Aluno';
      state.email = null;
      state.id = null;
    },
    setRedirectLocation: (state, action: PayloadAction<string>) => {
      state.redirectLocation = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(callRefreshToken.fulfilled, (state, action) => {
        setSession({
          token: action.payload.token,
          refreshToken: action.payload.refresh_token,
        });
        state.authenticated = true;
        state.name = '';
        state.email = null;
        state.id = null;
      })
      .addCase(callRefreshToken.rejected, (state, action) => {
        clearSession();
        state.authenticated = false;
        state.name = '';
        state.email = null;
        state.id = null;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        setSession({
          token: action.payload.token,
          refreshToken: action.payload.refresh_token,
        });
        state.authenticated = true;
        state.name = '';
        state.email = null;
        state.id = null;
      })
      .addCase(signInUserGoogle.fulfilled, (state, action) => {
        setSession({
          token: action.payload.token,
          refreshToken: action.payload.refresh_token,
        });
        state.authenticated = true;
        state.name = '';
        state.email = null;
        state.id = null;
      })
      .addCase(signInUserFacebook.fulfilled, (state, action) => {
        setSession({
          token: action.payload.token,
          refreshToken: action.payload.refresh_token,
        });
        state.authenticated = true;
        state.name = '';
        state.email = null;
        state.id = null;
      })
      .addCase(setUserInfo.fulfilled, (state, action) => {
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.id = action.payload.id;
      });
  },
});

export const { setAuth, clearAuth, setRedirectLocation } = userSlice.actions;

export default userSlice.reducer;
