const KEY = "token";
const REFRESH_KEY = "refresh-token";

interface ISession {
  token: string;
  refreshToken: string;
}

export const setSession = ({ token, refreshToken }: ISession) => {
  localStorage.setItem(KEY, token);
  localStorage.setItem(REFRESH_KEY, refreshToken);
}

export const getToken = () => (
  localStorage.getItem(KEY)
)

export const getRefreshToken = () => (
  localStorage.getItem(REFRESH_KEY)
);

export const clearSession = () => {
  localStorage.removeItem(KEY);
}
