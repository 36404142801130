import { combineReducers } from '@reduxjs/toolkit';
import checkoutReducer from 'stores/checkout';
import courseReducer from 'stores/course';
import coursesReducer from 'stores/courses';
import errorReducer from 'stores/errors';
import tagReducer from 'stores/tag';
import homeReducer from 'stores/home';
import modalReducer from 'stores/modals';
import ordersReducer from 'stores/orders';
import userReducer from 'stores/user';

const rootReducers = combineReducers({
  user: userReducer,
  modal: modalReducer,
  course: courseReducer,
  courses: coursesReducer,
  checkout: checkoutReducer,
  errors: errorReducer,
  home: homeReducer,
  orders: ordersReducer,
  tag: tagReducer,
});

export type RootState = ReturnType<typeof rootReducers>;
export default rootReducers;
