import QLoading from 'components/atoms/QLoading';
import QCadastroModal from 'components/organisms/QCadastroModal';
import QForgetPasswordModal from 'components/organisms/QForgetPasswordModal';
import QLoginModal from 'components/organisms/QLoginModal';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch } from 'stores';
import TagManager from 'react-gtm-module';
import {
  hideCadastroModal, hideLoginModal, setShowForgotModal,
} from 'stores/modals';
import { setUserInfo } from 'stores/user';
import { RootState } from 'stores/rootReducers';
import { RecoverPasswordContextProvider } from 'context/RecoverPasswordContext';

const Checkout = React.lazy(() => import('screens/anitta/checkout'));
const Course = React.lazy(() => import('screens/anitta/course'));
const Landing = React.lazy(() => import('screens/anitta/landing'));
const PurchaseTerm = React.lazy(() => import('screens/anitta/terms/purchase'));
const PrivacyTerm = React.lazy(() => import('screens/anitta/terms/privacy'));
const UseTerm = React.lazy(() => import('screens/anitta/terms/use'));
const OAuthGoogle = React.lazy(() => import('screens/oauth/google'));

const Footer = React.lazy(() => import('components/molecules/QFooter'));
const Header = React.lazy(() => import('components/molecules/QHeader'));

const Layout = ({ children }: any) => (
  <>
    <Header />
    <div>
      {children}
    </div>
    <Footer />
  </>
);

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="/checkout" element={<Layout><Checkout /></Layout>} />
    <Route path="/aluno" element={<Course />} />
    <Route path="/oauth/google" element={<OAuthGoogle />} />
    <Route path="/termo-de-adesao" element={<Layout><PurchaseTerm /></Layout>} />
    <Route path="/termos/politica-de-privacidade" element={<Layout><PrivacyTerm /></Layout>} />
    <Route path="/termos/termos-de-uso" element={<Layout><UseTerm /></Layout>} />
  </Routes>
);

const Router = () => {
  const dispatch = useAppDispatch;
  const modal = useSelector((state: RootState) => (state.modal));
  const user = useSelector((state: RootState) => (state.user));

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login_status',
        login_details: {
          id: user?.id,
          success: true,
        }
      }
    });

    if (user.authenticated) {
      if (user.name === '') {
        dispatch(setUserInfo());
      }
    }
  }, [user]);

  return (
    <>
      <AppRoutes />

      <QLoading open={modal.showLoadingModal} />
      <QCadastroModal
        open={modal.showCadastroModal.active}
        fnCallBack={() => dispatch(hideCadastroModal())}
      />
      <QLoginModal
        open={modal.showLoginModal.active}
        fnCallBack={() => dispatch(hideLoginModal())}
      />
      <RecoverPasswordContextProvider>
        <QForgetPasswordModal
          open={modal.showForgotModal}
          fnCallBack={() => dispatch(setShowForgotModal())}
        />
      </RecoverPasswordContextProvider>
    </>
  );
};
export default Router;
