export default {
  "empty": "{{ user }}, seus pedidos aparecerão aqui! Até lá, explore o nosso site com as sugestões abaixo.",
  "expiration": {
    "expired_at": "Acesso expirado em {{ expiration }}",
    "enabled_until": "Acesso até  {{ expiration }}"
  },
  "detail": {
    "title": "Pedido {{ id }}",
    "text": "Detalhes do Pedido",
    "student_data": {
      "title": "Dados do aluno"
    },
    "resume_order": {
      "title": "Resumo do Pedido",
      "course_price": "Valor do curso",
      "course_discount": "Desconto",
      "course_price_total": "Valor total",
      "student_title": "Dados do aluno"
    },
    "course": {
      "title": "Veja o que você comprou",
      "duration": "{{ hours }} horas",
      "access_course": "Acessar Curso"
    },
    "payment": {
      "pix": "Pix",
      "credit_card": "Cartão de Crédito",
      "boleto": "Boleto",
      "short": {
        "pix": "Pix",
        "credit_card": "Crédito",
        "boleto": "Boleto",
      },
      "card": "($t(detail.payment.short.{{ kind }})) **** {{card, hideCreditCard}}"
    }
  }
}
