import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  EnrolledCourseType,
  SuggestedCourseType,
  CourseType,
  ContentType,
  ViewType,
  CourseContent,
} from 'entities/course';
import { ApiErrorType } from 'entities/error';
import CoursesAPI from 'services/courses';
import { useAppDispatch } from 'stores';
import { hideLoading, showLoading, setShowIframeModal } from 'stores/modals';

export type ContentListItem = CourseContent & {
  id: string;
  topicId: string;
  sectionId: string;
  available: boolean;
}

export type CourseStoreType = {
  enrolledCourses: EnrolledCourseType[],
  suggestedCourses: SuggestedCourseType[],
  course: CourseType | undefined;
  contentList: ContentListItem[];
  content: ContentType | undefined;
  error?: ApiErrorType,
  viewed: { [id: string]: boolean },
}

export interface ViewInterface {
  contentId: string;
}

export interface CreateRatingInterface {
  kind: string;
  reference: string;
  score: number;
  motive?: string;
}

const initialState: CourseStoreType = {
  enrolledCourses: [],
  suggestedCourses: [],
  course: undefined,
  contentList: [],
  content: undefined,
  viewed: {},
};

export const getEnrolledCourses = createAsyncThunk<
  EnrolledCourseType[],
  void,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/getEnrolledCourses',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      CoursesAPI.getEnrolledCourses()
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const getSuggestedCourses = createAsyncThunk<
  SuggestedCourseType[],
  void,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/getSuggestedCourses',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      CoursesAPI.getSuggestedCourses()
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const getCourse = createAsyncThunk<
  CourseType,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/getCourse',
  async (courseId, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      CoursesAPI.getEnrolledCourse(courseId)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const setContent = createAsyncThunk<
  ContentType,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/setContent',
  async (contentId, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      CoursesAPI.getContent(contentId)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          thunkAPI.dispatch(setShowIframeModal({ active: true, title: response.data.name }));
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const createView = createAsyncThunk<
  ViewInterface,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/createView',
  async (contentId, thunkAPI) => (
    CoursesAPI.createView(contentId)
      .then(() => ({ contentId }))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  ),
);

export const destroyView = createAsyncThunk<
  ViewInterface,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/destroyView',
  async (contentId, thunkAPI) => (
    CoursesAPI.destroyView(contentId)
      .then(() => ({ contentId }))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  ),
);

export const createRating = createAsyncThunk<
  { score: number; },
  CreateRatingInterface,
  {
    rejectValue: ApiErrorType,
  }
>(
  'courses/createRating',
  async ({ kind, reference, score, motive }, thunkAPI) => (
    CoursesAPI.createRating({ kind, reference, score, motive })
      .then(() => ({ score }))
      .catch((error) => thunkAPI.rejectWithValue(error.response.data))
  ),
);

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearContent: (state) => {
      state.content = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnrolledCourses.fulfilled, (state, action) => {
        state.enrolledCourses = action.payload;
      })
      .addCase(getSuggestedCourses.fulfilled, (state, action) => {
        state.suggestedCourses = action.payload;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        const sorter = (a: any, b: any) => (
          a.order - b.order
        );

        const course = {
          ...action.payload,
          sections: action.payload.sections.sort(sorter).map((section) => (
            {
              ...section,
              topics: section.topics.sort(sorter).map((topic) => (
                {
                  ...topic,
                  contents: topic.contents.sort(sorter)
                }
              ))
            }
          ))
        }

        const contentList = course.sections.flatMap((section) => (
          section.topics.flatMap((topic) => (
            topic.contents.flatMap((content) => (
              {
                ...content,
                sectionId: section.id,
                topicId: topic.id,
                available: section.available,
              }
            ))
          ))
        ));

        state.course = course;
        state.contentList = contentList;
        action.payload.views.forEach((view: ViewType) => {
          state.viewed[view.content_id] = true;
        });
      })
      .addCase(setContent.fulfilled, (state, action) => {
        state.content = action.payload;
      })
      .addCase(createView.fulfilled, (state, action) => {
        state.viewed[action.payload.contentId] = true;
      })
      .addCase(destroyView.fulfilled, (state, action) => {
        state.viewed[action.payload.contentId] = false;
      })
      .addCase(createRating.fulfilled, (state, action) => {
        state.content!.rating.score = action.payload.score;
      });
  },
});

export const { clearContent } = coursesSlice.actions;

export default coursesSlice.reducer;
