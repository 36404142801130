/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-no-useless-fragment */

import { ReactNode, useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { init, Identify, identify, track, setUserId, setTransport } from '@amplitude/analytics-browser';
import { TransportType } from '@amplitude/analytics-types';
import { useOfferContext } from "../context/OfferContext";

const AmplitudeProvider = ({ children }: { children: ReactNode }) => {
  const { offer, course } = useOfferContext();
  const { user } = useSelector((state: RootState) => state);

  const { authenticated, id, email, name } = user;

  const handleDropoff = useCallback((href?: string) => {
    // eslint-disable-next-line no-restricted-globals
    if(location.pathname !== '/checkout') return;

    setTransport('beacon' as TransportType);
    track('checkout dropoff', {
      href,
      'course id': course.id,
      'course name': course.name,
      'course duration': course.duration / 60.0,
      'tag name': course.tags.map((tag: any) => tag.name),
      'instructor name': course.instructors.map((instructor: any) => instructor.name),
      'productor name': course.productor.name,
      'offer id': offer.id,
      'offer price': offer.price.cents / 100.0,
      'offer reference price': offer.referencePrice.cents / 100.0,
      'payment method': null,
    })
    setTransport('http' as TransportType);
  }, [course, offer]);

  const handleClick = useCallback((e: MouseEvent) => {
    if((e.target as HTMLElement).tagName !== 'A') return;
    handleDropoff((e.target as HTMLAnchorElement).href);
  }, [handleDropoff]);

  const handleStartDropoff = useCallback((e: CustomEvent) => {
    handleDropoff(e.detail.href);
  }, [handleDropoff]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    init(process.env.REACT_APP_AMPLITUDE_KEY!, undefined, {
      includeUtm: true,
      includeGclid: true,
      includeFbclid: true,
      // saveParamsReferrerOncePerSession: true,
      // logAttributionCapturedEvent: true
    });
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClick);
    // @ts-ignore
    window.addEventListener('startDropoff', handleStartDropoff);
    return () => {
      // @ts-ignore
      window.removeEventListener('startDropoff', handleStartDropoff);
      window.removeEventListener('click', handleClick);
    }
  }, [handleClick, handleDropoff, handleStartDropoff])

  useEffect(() => {
    setUserId(id);

    if (authenticated) {
      const event = new Identify();

      if (email && name) {
        event.set('email', email);
        event.set('name', name);
      }

      identify(event);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return <>{children}</>;
};

export default AmplitudeProvider;
