import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalsType, SetIframeModalPayload } from '../../entities/modals';

const initialState: ModalsType = {
  showForgotModal: false,
  showLoadingModal: false,
  showIframeModal: {
    active: false,
    title: '',
    minimized: false,
  },
  showCadastroModal: {
    active: false,
    offerId: null
  },
  showLoginModal: {
    active: false,
    offerId: null,
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setShowForgotModal: (state) => {
      state.showForgotModal = !state.showForgotModal;
    },
    setShowIframeModal: (state, action: PayloadAction<SetIframeModalPayload>) => {
      state.showIframeModal.active = action.payload.active;
      state.showIframeModal.title = action.payload?.title || '';
    },
    hideIframeModal: (state) => {
      state.showIframeModal.active = false;
    },
    resizeIframeModal: (state, action: PayloadAction<boolean | undefined>) => {
      state.showIframeModal.minimized = action.payload;
    },
    showCadastroModal: (state, { payload: offerId }) => {
      state.showCadastroModal = {
        active: true,
        offerId,
      };
      state.showLoginModal = {
        active: false,
        offerId: null,
      };
    },
    showLoading: (state) => {
      state.showLoadingModal = true;
    },
    hideLoading: (state) => {
      state.showLoadingModal = false;
    },
    hideCadastroModal: (state) => {
      state.showCadastroModal = {
        ...state.showCadastroModal,
        active: false,
      };
    },
    showLoginModal: (state, { payload: offerId }) => {
      state.showLoginModal = {
        active: true,
        offerId
      };
      state.showCadastroModal = {
        active: false,
        offerId: null,
      };
    },
    hideLoginModal: (state) => {
      state.showLoginModal = {
        ...state.showLoginModal,
        active: false,
      };
    },
  },
});

export const {
  setShowForgotModal,
  showCadastroModal,
  hideCadastroModal,
  showLoginModal,
  hideLoginModal,
  showLoading,
  hideLoading,
  setShowIframeModal,
  hideIframeModal,
  resizeIframeModal,
} = modalSlice.actions;

export default modalSlice.reducer;
