/* eslint-disable arrow-body-style */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import courseMapper from 'services/mappers/courses';
import { Course } from '../../entitys/course';

const initialState: Course = {
  duration: 0,
  name: '',
  slug: '',
  description: '',
  benefits: [],
  price: 0,
  referencePrice: 0,
  productors: [],
  productor: {
    name: '',
    slug: '',
    images: {},
  },
  tags: [],
  instructors: [],
  ratingAverage: 0,
  ratingCount: 0,
  sections: [],
  offerId: '',
  ratings: {
    average: 0,
    count: 0,
  },
  images: {},
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourse: (state, action: PayloadAction<Course>) => {
      const courseInfo = courseMapper(action.payload);
      return { ...state, ...courseInfo };
    },
  },
});

export const { setCourse } = courseSlice.actions;

export default courseSlice.reducer;
