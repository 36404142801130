/* eslint-disable arrow-body-style */

const sorter = (a: any, b: any) => (
  a.order - b.order
);

const courseMapper = (course: any) => ({
  id: course.id,
  duration: course.duration,
  name: course.name,
  description: course.description,
  benefits: course.offer.benefits.map(({ text } : any) => text),
  price: course.offer.price.cents / 100.0,
  referencePrice: course.offer.reference_price.cents / 100.0,
  productors: course.productors.map(({ name }: any) => name),
  productor: course.productor,
  tags: course.tags.map(({ name }: any) => name),
  instructors: course.instructors.map(({ name }: any) => name),
  ratingAverage: course.ratings.average,
  ratingCount: course.ratings.count,
  slug: course.slug,
  sections: course.sections.sort(sorter).map(({ id, name, topics }: any) => {
    return {
      id,
      name,
      topics: topics.sort(sorter).map((topic: any) => ({ id: topic.id, name: topic.name })),
    };
  }),
  images: course.images,
  offerId: course.offer.id,
  offer: course.offer,
});

export default courseMapper;
