import validator from "validator";

export const emailValidation = (input: string): string | null => {
  if (validator.isEmpty(input)) {
    return 'Você deve digitar um e-mail';
  }

  if (!validator.isEmail(input)) {
    return 'E-mail inválido';
  }

  return null;
}

export const passwordValidation = (input: string): string | null => {
  if (validator.isEmpty(input)) {
    return 'Você deve digitar uma senha';
  }

  return null;
}

export const signupNameValidation = (input: string): string | null => {
  if (validator.isEmpty(input)) {
    return 'Você deve digitar um nome';
  }

  return null;
}

export const verifyCPF = (input: string): string | null => {
  let Soma;
  let Resto;
  Soma = 0;
  if (input === "00000000000") return 'CPF inválido!1';

  for (let i = 1; i <= 9; i += 1) Soma += parseInt(input.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(input.substring(9, 10), 10)) return 'CPF inválido!2';

  Soma = 0;
  for (let i = 1; i <= 10; i += 1) Soma += parseInt(input.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(input.substring(10, 11), 10)) return 'CPF inválido!3';
  return null;
}
