import DefaultMainBgImg from 'assets/images/defaultLoginImage.png';
import QFacebookLogo from 'assets/images/QFacebookLogo';
import QGoogleLogo from 'assets/images/QGoogleLogo';
import QModalBackgroundImg from 'components/atoms/QModalBackgroundImg';
import QModalBodyLeft from 'components/atoms/QModalBodyLeft';
import QModalBodyRight from 'components/atoms/QModalBodyRight';
import QModalButton from 'components/atoms/QModalButton';
import QModalCheckbox from 'components/atoms/QModalCheckbox';
import QModalContainer from 'components/atoms/QModalContainer';
import QModalDoubleDivider from 'components/atoms/QModalDoubleDivider';
import QModalHeader from 'components/atoms/QModalHeader';
import QModalInput from 'components/atoms/QModalInput';
import QModalDescriptions from 'components/atoms/QTypography/QModalDescriptions';
import QModalLink from 'components/atoms/QTypography/QModalLink';
import QModalTitle from 'components/atoms/QTypography/QModalTitle';
import QModalOfferInformation from 'components/molecules/QModalOfferInformation';
import { useOfferContext } from 'context/OfferContext';
import { useToastContext } from 'context/ToastContext';
import { SignUpRequest, signUpValidation } from 'entities/user';
import React, { FormEvent, useEffect } from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginApi from 'services/auth';
import { RootState, useAppDispatch } from 'stores';
import { addInputError, removeInputError } from 'stores/errors';
import { hideCadastroModal, showLoginModal } from 'stores/modals';
import { setAuth, signInUserFacebook, signInUserGoogle, setRedirectLocation } from 'stores/user';
import { emailValidation, passwordValidation, signupNameValidation } from 'utils/validation';
import loadGoogleSignIn from 'scripts/googleSignIn';
import './styles.css';

export interface QCadastroModalProps {
  open: boolean
  fnCallBack: React.Dispatch<React.SetStateAction<boolean>>
}

export interface QCadastroModalChecks {
  terms: boolean,
  privacy: boolean,
}

const initialSignUpRequest = {
  error: {
    name: '',
    email: '',
    password: '',
  },
  name: '',
  email: '',
  password: '',
};
const initialSignUpChecks = {
  terms: false,
  privacy: false,
};

const QCadastroModal = (Props: QCadastroModalProps) => {
  const { open, fnCallBack } = Props;
  const dispatch = useAppDispatch;
  const navigate = useNavigate();
  const { offer } = useOfferContext();
  const { openToast } = useToastContext();
  const { showCadastroModal } = useSelector((state: RootState) => state.modal);
  const { inputErrors } = useSelector((state: RootState) => state.errors);
  const [signupRequest, setSignupRequest] = React.useState<SignUpRequest>(initialSignUpRequest);
  const [signupChecks, setSignupChecks] = React.useState<QCadastroModalChecks>(initialSignUpChecks);

  useEffect(() => {
    loadGoogleSignIn(null);
  });

  useEffect(() => {
    if (!open) {
      setSignupRequest(initialSignUpRequest);
      setSignupChecks(initialSignUpChecks);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      dispatch(setRedirectLocation(window.location.pathname));
    }
  }, [open]);

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const getTitle = () => {
    if (!showCadastroModal.offerId) {
      if (dimensions.width > 768) {
        return 'Cadastre-se agora e venha fazer parte da comunidade';
      }

      return 'Cadastre-se e venha fazer parte da comunidade';
    }

    return 'Cadastre-se para continuar comprando este curso';
  };

  const bgImage = offer?.images?.modal || DefaultMainBgImg;

  const handleSignUp = (e: FormEvent) => {
    e.preventDefault();

    const { errors, isValid } = signUpValidation(signupRequest);

    if (!isValid) {
      return setSignupRequest({ ...signupRequest, error: errors });
    }

    setSignupRequest({ ...signupRequest, error: initialSignUpRequest.error });

    return LoginApi.register(signupRequest).then((response) => {
      dispatch(setAuth(response));
      dispatch(hideCadastroModal());

      if (!offer) {
        navigate('../meus-cursos');
      }

      setSignupRequest(initialSignUpRequest);
    }).catch(error => {
      if (error.response.data.message.includes("Weak password")) {
        return openToast({
          message: 'Senha fraca, por favor insira uma senha mais forte.',
          type: 'error'
        });
      }

      if (error.response.data.message.includes("index_users_on_email")) {
        return openToast({
          message: 'Já existe uma conta com esse e-mail.',
          type: 'error'
        });
      }

      return openToast({
        message: 'Ocorreu um erro ao tentar criar seu cadastro, tente novamente.',
        type: 'error'
      });
    });
  };

  const handleLoginGoogle = (token: string) => (
    dispatch(signInUserGoogle({ token }))
      .then((response) => {
        if (signInUserGoogle.fulfilled.match(response)) {
          dispatch(hideCadastroModal());

          if (!offer) {
            navigate('../meus-cursos');
          }
        }
      })
  );

  const handleLoginFacebook = (token: string) => (
    dispatch(signInUserFacebook({ token }))
      .then((response) => {
        if (signInUserFacebook.fulfilled.match(response)) {
          dispatch(hideCadastroModal());

          if (!offer) {
            navigate('../meus-cursos');
          }
        }
      })
  );

  const validateName = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = signupNameValidation(e.target.value)

    if (!errorMessage) {
      return dispatch(removeInputError('signupName'));
    }

    return dispatch(addInputError({
      'signupName': errorMessage,
    }))
  }

  const handleNameStatus = (): 'Error' | 'Success' | undefined => {
    if (signupRequest.error.name || inputErrors.signupName) {
      return 'Error'
    }

    if (signupRequest.name && !inputErrors.signupName) {
      return 'Success'
    }

    return undefined
  }

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = emailValidation(e.target.value)
    if (!errorMessage) {
      return dispatch(removeInputError('signupEmail'));
    }

    return dispatch(addInputError({
      'signupEmail': errorMessage,
    }))
  }

  const handleEmailStatus = (): 'Error' | 'Success' | undefined => {
    if (signupRequest.error.email || inputErrors.signupEmail) {
      return 'Error'
    }

    if (signupRequest.email && !inputErrors.signupEmail) {
      return 'Success'
    }

    return undefined
  }

  const validatePassword = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = passwordValidation(e.target.value)

    if (!errorMessage) {
      return dispatch(removeInputError('signupPassword'));
    }

    return dispatch(addInputError({
      'signupPassword': errorMessage,
    }))
  }

  const handlePasswordStatus = (): 'Error' | 'Success' | undefined => {
    if (signupRequest.error.password || inputErrors.signupPassword) {
      return 'Error'
    }

    if (signupRequest.password && !inputErrors.signupPassword) {
      return 'Success'
    }

    return undefined
  }

  const handleDisableSignupButton = () => {
    if (
      signupChecks.terms &&
      signupChecks.privacy &&
      signupRequest.name &&
      signupRequest.email &&
      signupRequest.password &&
      !inputErrors.signupName &&
      !inputErrors.signupEmail &&
      !inputErrors.signupPassword
    ) {
      return false;
    }
    return true;
  }

  return (
    <QModalContainer open={open} type="Cadastro">
      <QModalHeader fnCallback={() => {
        setSignupRequest(initialSignUpRequest);
        setSignupChecks(initialSignUpChecks);
        fnCallBack(false);
      }}
      />

      <div className="QBodyContainer">
        <QModalBodyLeft>
          <QModalBackgroundImg src={bgImage} offerId={offer?.id} logo={offer?.images?.login_logo} >
            {
              showCadastroModal.offerId && offer && (
                <QModalOfferInformation offer={offer} />
              )
            }
          </QModalBackgroundImg>
        </QModalBodyLeft>

        <QModalBodyRight>
          <form onSubmit={handleSignUp}>
            <QModalTitle>
              {getTitle()}
            </QModalTitle>

            <div className="QMensagemLogin">
              <QModalDescriptions>
                Já tem um cadastro? &nbsp;
              </QModalDescriptions>
              <QModalLink
                onClick={() => {
                  dispatch(showLoginModal(showCadastroModal.offerId));
                }}
              >
                Entrar
              </QModalLink>
            </div>

            <div className="QSocialLoginContainer">
              <QModalButton
                style={{ backgroundColor: '#FFFFFF', color: '#374151', border: '1px solid #D3D6DC'}}
                onClick={() => {
                  const domain = `${window.location.protocol}//${window.location.host}`;
                  const redirect_uri = `${domain}/oauth/google`;

                  window.auth2.grantOfflineAccess({redirect_uri});
                }}
              >
                <QGoogleLogo className="QGoogleLogo" />
                Cadastre-se pelo Google
              </QModalButton>

              <QModalButton
                style={{ backgroundColor: '#1977F3', marginTop: 16 }}
                onClick={() => window.FB.login((response: any) => {
                  const { authResponse } = response;
                  if (authResponse) {
                    handleLoginFacebook(response.authResponse.accessToken);
                  }
                }, { scope: 'public_profile,email' })}
              >
                <QFacebookLogo className="QFacebookLogo" />
                Cadastre-se pelo Facebook
              </QModalButton>
            </div>

            <QModalDoubleDivider>
              <QModalDescriptions style={{ fontSize: 14 }}>
                ou
              </QModalDescriptions>
            </QModalDoubleDivider>

            <QModalInput
              placeholder="Nome"
              type="text"
              required
              handleOnChange={(e) => {
                validateName(e)
                setSignupRequest({ ...signupRequest, name: e.target.value })
              }}
              handleOnBlur={validateName}
              errorMessage={signupRequest.error.name || inputErrors.signupName}
              defaultValue={signupRequest.name}
              status={handleNameStatus()}
            />

            <QModalInput
              placeholder="E-mail"
              type="text"
              required
              handleOnChange={(e) => {
                validateEmail(e)
                setSignupRequest({ ...signupRequest, email: e.target.value })
              }}
              handleOnBlur={validateEmail}
              errorMessage={signupRequest.error.email || inputErrors.signupEmail}
              status={handleEmailStatus()}
              defaultValue={signupRequest.email}
            />
            <QModalInput
              placeholder="Senha - Mínimo de 8 caracteres"
              type="password"
              required
              minlength={8}
              handleOnChange={(e) => {
                validatePassword(e)
                setSignupRequest({ ...signupRequest, password: e.target.value })
              }}
              handleOnBlur={validatePassword}
              errorMessage={signupRequest.error.password || inputErrors.signupPassword}
              defaultValue={signupRequest.password}
              status={handlePasswordStatus()}
            />

            <div className="QCheckboxContainer">
              <QModalCheckbox
                required
                onChange={() => setSignupChecks({ ...signupChecks, terms: !signupChecks.terms })}
              >
                <span className='QCadastroSmallDescription'>
                  Estou de acordo com os&nbsp;
                </span>
                <QModalLink
                  className="SignupLinks"
                  onClick={() => window.open('/termos/termos-de-uso', 'Termos de uso', 'height=500,width=500')?.focus()}
                >
                  Termos de uso
                </QModalLink>
              </QModalCheckbox>
              <QModalCheckbox
                onChange={() => setSignupChecks({ ...signupChecks, privacy: !signupChecks.privacy })}
              >
                <span className='QCadastroSmallDescription'>
                  Estou de acordo com a&nbsp;
                </span>
                <QModalLink className="SignupLinks" onClick={() => window.open('/termos/politica-de-privacidade', 'Política de privacidade', 'height=500,width=500')?.focus()}>
                  Política de Privacidade
                </QModalLink>
              </QModalCheckbox>
            </div>

            <QModalButton type="submit" disabled={handleDisableSignupButton()}
            >
              Criar conta gratuitamente
            </QModalButton>
          </form>
        </QModalBodyRight>

      </div>

    </QModalContainer>
  );
};

export default QCadastroModal;
